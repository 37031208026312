import { GetDatasetDataRequest } from '@amzn/fox-den-cost-planning-lambda';
import { useMemo, useState } from 'react';
import { ColDef, IServerSideDatasource } from 'ag-grid-community';
import { useParams } from 'react-router-dom';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';
import { getColumnDefs, useDatasetSchema } from 'src/utils/planning/useDatasetSchema';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useSsrmDataset } from 'src/pages/commons/data-grids/useSsrmDataset';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

class OutputSsrmDatasetAttributes {
  columnDefs: ColDef[] | undefined = [];
  datasetRowCount: number | undefined = undefined;
  datasetRequest: GetDatasetDataRequest | undefined = undefined;
}

export const useSsrmOutputDataset = (currentDataset: SupportedDatasetType) => {
  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: featureFlags } = useFeatureFlags();

  const { schema, mappingSchema } = useDatasetSchema({ batchMetadata, currentDataset });

  const { getDataset, isFetchingDataset } = useSsrmDataset(currentDataset);

  const [attributes, setAttributes] = useState(() => new OutputSsrmDatasetAttributes());

  const serverSideDatasource = useMemo<IServerSideDatasource | undefined>(() => {
    if (!batchMetadata) return undefined;

    return {
      getRows: async (params) => {
        const { request, success, fail } = params;

        const { datasetData, datasetMetadata, datasetRequest } = await getDataset(request);

        const columnDefs = getColumnDefs({
          batchMetadata,
          datasetMetadata,
          schema,
          mappingSchema,
          featureFlags,
          isSsrmDataGrid: true,
        });

        if (!datasetData || !datasetMetadata || !columnDefs) {
          fail();
          return;
        }

        const rowData = datasetData.rowData || [];
        const datasetRowCount = datasetData.totalRowCount ?? undefined;

        setAttributes({ columnDefs, datasetRowCount, datasetRequest });

        success({ rowData, rowCount: datasetRowCount });
      },
    };
  }, [batchMetadata, featureFlags, getDataset, mappingSchema, schema]);

  return { serverSideDatasource, isFetchingDataset, ...attributes };
};
