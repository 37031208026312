import { Button } from '@amzn/awsui-components-react/polaris';
import {
  UploadBatchEditsRequest,
  ValidationStatusEnumEnum,
} from '@amzn/fox-den-cost-planning-lambda';
import {
  CellSelectionOptions,
  RowSelectionOptions,
  ToolPanelVisibleChangedEvent,
} from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport, BiImport, BiRefresh } from 'react-icons/bi';
import { BsFillArchiveFill } from 'react-icons/bs';
import { FaRegClone, FaRegSave } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { RiImportFill, RiExportFill } from 'react-icons/ri';
import { useParams } from 'react-router';
import { find } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { OperationType, useUploadEditsMutation } from 'src/api/mutation/useUploadEditsMutation';
import { RequestVariables } from 'src/api/api-utils';
import { useBatchBusinessRules, useStaticFilterList } from 'src/api/query/useBatchDimensionValues';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { usePlanDataset } from 'src/api/query/usePlanDataset';
import UploadDatasetModal from 'src/pages/plan-manager-page/plan-input-tab/components/UploadDatasetModal';
import {
  IInlineEditRecord,
  agRowInlineEditsAdd,
  agRowInlineEditsDelete,
  IInlineEditType,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridInlineEditHelper';
import {
  IRowValidationCellData,
  IRowValidationRowWithId,
  IValidationRowTransformer,
  getValidationRowTransformer,
  standardizeInitialData,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridRowValidationHelper';
import { SelectChangeEvent } from 'src/common/EventType';
import {
  useTriggerComputeFromCPU,
  useTriggerComputeFromMM,
  useTriggerLunaExport,
  useTriggerOtrObAutomatedDatasetImport,
  useTriggerPlanImportBaseline,
} from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { downloadFile, getDatasetErrorInfo } from 'src/utils/errorMessage';
import {
  SupportedDatasetType,
  PlanTypeId,
  RegionId,
  TriggerType,
} from 'src/utils/planning/planetModel';
import { currentDateTime } from 'src/utils/time';
import { getLastUpdatedOnOfDataset } from 'src/utils/planning/batchMetadataModel';
import {
  getExportProcessHeaderCallback,
  useColumnDefs,
  useDatasetSchema,
} from 'src/utils/planning/useDatasetSchema';
import { useUnsavedChangesModal } from 'src/pages/commons/plan-actions/useUnsavedChangesModal';
import UserConfirmModal, { ConfirmModalInfo } from 'src/common/UserConfirmModal';
import { GridComponentProps } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import DatasetGridContainer, {
  DataGridActionButtonGroups,
  OTRAutomatedImportDatasetsWithTrailingSelector,
} from 'src/pages/commons/plan-views/DatasetGridContainer';
import { TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES } from 'src/pages/plan-manager-page/plan-output-tab/PlanOutputTab';
import { generateStaticSiteList } from 'src/pages/commons/plan-filters/StaticFilterList';
import { IFilterList, useFilterComponent } from 'src/pages/commons/plan-filters/useFilterComponent';
import { buildComputeParams } from 'src/api/mutation/useTriggerComputeMutation';
import { useAsyncUploadEditsMutation } from 'src/api/mutation/useAsyncUploadEditsMutation';
import { usePlanPageContext } from 'src/pages/plan-manager-page/PlanManagerPage';
import { useIsPlanPolling } from 'src/pages/commons/compute-helpers/useComputationPollingManager';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import { useAgGridResizeColumns } from 'src/common/ag-grid/useAgGridResizeColumns';
import { useAgGridCalculatedHeight } from 'src/common/ag-grid/useAgGridCalculatedHeight';
import { useAgGridPivotModeConfig } from 'src/common/ag-grid/useAgGridPivotModeConfig';
import {
  getErrorRowIds,
  navigateToNextErrorRowIds,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridErrorNavigationHelper';
import { useInputAgGridDefaultColDef } from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/useInputAgGridDefaultColDef';
import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import { AG_INPUT_PAGINATION_PAGE_SIZE } from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { useCsrmGridParams } from 'src/pages/commons/data-grids/useCsrmGridParams';

// TODO: remove this hardcoded, retrieve list from DimensionAPI
export const UTRLargeDatasetNames: Set<SupportedDatasetType> = new Set([
  SupportedDatasetType.PLANET_UTR_VARIABLE_BASE_CPH,
  SupportedDatasetType.PLANET_UTR_VARIABLE_LEAVE_OF_ABSENCE_PERCENTAGE,
  SupportedDatasetType.PLANET_UTR_VARIABLE_SURCHARGES,
  SupportedDatasetType.PLANET_UTR_VARIABLE_NON_HR_COST,
  SupportedDatasetType.UTR_PRODUCTIVITY_VARIABLE_PLANNED_HIRES_RELEASES_OVERRIDE,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_OVERLAYS,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HOURS_ASSUMPTIONS,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_LEARNING_CURVES,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_BASELINE_RATES,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_VOLUME_OVERRIDE,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_STARTING_HEADCOUNT_OVERRIDE,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HEADCOUNT_ADJUSTMENTS_GUARDRAILS,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_RULE_BASED_HOURS,
]);

export interface RowValidationGridData {
  rowData: Record<string, IRowValidationCellData>[];
  validHeaders: string[];
  validationRowTransformer: IValidationRowTransformer;
}

const PlanInputDataGrid = ({
  headerText,
  description,
  datasetOptions,
  currentDataset,
  setCurrentDataset,
  currentGridPivotMode,
  setCurrentGridPivotMode,
  currentGridToolPanel,
  setCurrentGridToolPanel,
}: GridComponentProps) => {
  const { t } = useTranslation();

  const { data: featureFlags } = useFeatureFlags();

  const [inlineEdits, setInlineEdits] = useState<IInlineEditRecord[]>([]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [gridData, setGridData] = useState<RowValidationGridData | null>(null);

  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  const [isUpdatingDataset, setIsUpdatingDataset] = useState(false);

  const [shouldTriggerDatasetUpdate, setShouldTriggerDatasetUpdate] = useState(false);

  const [confirmModalInfo, setConfirmModalInfo] = useState<ConfirmModalInfo>(null);

  /**
   * error rows that are saved to server and with unresolved errors
   * - include uploaded dataset or manually saved data
   * - not include errors that are not saved to server yet
   */
  const [savedUnsolvedErrorRowIds, setSavedUnsolvedErrorRowIds] = useState<Set<number>>(new Set());

  const { isHasUnsavedEdits, setIsHasUnsavedEdits, setInProgressAsyncUploadEditsDatasets } =
    usePlanPageContext();

  const indexedId = useRef(0);

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { aggFuncs } = useCsrmGridParams();

  const { processingComputeTrigger } = useIsPlanPolling(batchMetadata);

  const { triggerOtrObAutomatedDatasetImport } = useTriggerOtrObAutomatedDatasetImport();

  const gridRef = useRef<AgGridReact>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const isLargeDataset = UTRLargeDatasetNames.has(currentDataset);

  const inlineEditsDisabled =
    isLargeDataset ||
    batchMetadata?.status === 'CLOSED' ||
    processingComputeTrigger === TriggerType.TOPS_DOWN_FORECAST_VOLUME_DATASET_IMPORT ||
    processingComputeTrigger === TriggerType.TOPS_DOWN_FORECAST_BASELINE_CPU_DATASET_IMPORT;

  const planRequiresSiteFilter = isLargeDataset;

  const { data: sitesFilterList, isError: noSitesFilterList } = useStaticFilterList(
    {
      planType: batchMetadata?.costType ?? '',
      subGroup: batchMetadata?.subGroup ?? '',
      region: batchMetadata?.region ?? '',
    },
    { disabled: !batchMetadata || !planRequiresSiteFilter },
  );

  const filterConfig = useMemo(() => {
    const filterInfo: IFilterList = [];

    if (planRequiresSiteFilter) {
      filterInfo.push({
        name: 'site',
        label: 'site_name_filter_label',
        options: noSitesFilterList ? generateStaticSiteList() : sitesFilterList ?? [],
      });
    }

    return filterInfo;
  }, [noSitesFilterList, planRequiresSiteFilter, sitesFilterList]);

  const { filtersValid, filterMap, FilterComponent } = useFilterComponent(filterConfig);

  const getNewIndexedId = useCallback(() => `${indexedId.current++}`, []);

  const { schema, mappingSchema } = useDatasetSchema({ batchMetadata, currentDataset });

  const { columnDefs } = useColumnDefs({
    batchMetadata,
    schema,
    mappingSchema,
    featureFlags,
    isSsrmDataGrid: false,
  });

  const { mutate: asyncUploadEdits } = useAsyncUploadEditsMutation({
    onSuccess: (data, variables) => {
      setInProgressAsyncUploadEditsDatasets(
        (prev) => new Set([...prev, ...variables.datasetNames] as SupportedDatasetType[]),
      );
    },
  });

  const { triggerImportBaseline } = useTriggerPlanImportBaseline();

  const { triggerComputeFromCPU } = useTriggerComputeFromCPU();

  const { triggerComputeFromMM } = useTriggerComputeFromMM();

  const { triggerLunaExport } = useTriggerLunaExport();

  const { mutate: updateNormalDatasetMutation } = useUploadEditsMutation({
    onMutate: () => {
      setIsUpdatingDataset(true);
    },
    onError: () => {
      setIsUpdatingDataset(false);
    },
    onSuccess: () => {
      const onSuccess = () => setInlineEdits([]);
      const onSettled = () => setIsUpdatingDataset(false);

      if (batchMetadata?.batchId) {
        switch (currentDataset) {
          case SupportedDatasetType.TOPS_DOWN_FORECAST_COEFFICIENT:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_CHC:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_MANUAL_BRIDGE:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_FIXED:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_VARIABLE:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_SHIPPED_UNITS:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_INPUT:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_NON_DESIGNATED_INPUT:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_OUTPUT:
            onSuccess();
            onSettled();
            break;
          case SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST:
            triggerComputeFromCPU({ batchId: batchMetadata.batchId }, { onSuccess, onSettled });
            break;
          case SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST:
            triggerComputeFromMM({ batchId: batchMetadata.batchId }, { onSuccess, onSettled });
            break;
          default:
            asyncUploadEdits(
              { batchId: batchMetadata.batchId, datasetNames: [currentDataset] },
              { onSuccess, onSettled },
            );
            break;
        }
      }
    },
  });

  /**
   * Literally does nothing except for changing the lastUpdatedTime of the currentDataset
   * due to bug in BE which doesn't update lastUpdatedTime in UTR_Large_Data_Update_Load
   */
  const { mutate: updateLargeDatasetMutation } = useUploadEditsMutation({
    onMutate: () => {
      setIsUpdatingDataset(true);
    },
    onError: () => {
      setIsUpdatingDataset(false);
    },
    onSuccess: (data, variables) => {
      const onSuccess = () => setInlineEdits([]);
      const onSettled = () => setIsUpdatingDataset(false);

      if (batchMetadata?.batchId) {
        asyncUploadEdits(
          {
            batchId: batchMetadata.batchId ?? '',
            datasetNames: [variables.datasetType],
            parameters: buildComputeParams({
              batchEditsFile: variables.batchEditsFile,
              headers: variables.headers,
              partitionS3VersionMap: variables.partitionS3VersionMap,
              partitionLocator: variables.partitionLocator,
              operation: variables.operation,
            }),
          },
          { onSuccess, onSettled },
        );
      }
    },
  });

  const validDatasetLocation = useMemo(
    () => !!find(batchMetadata?.datasetLocations, { datasetName: currentDataset }),
    [batchMetadata?.datasetLocations, currentDataset],
  );

  const {
    data: rawData,
    refetch: refetchPlanDataset,
    isFetching: planDatasetIsFetching,
  } = usePlanDataset(
    {
      datasetName: currentDataset,
      batchId: batchMetadata?.batchId ?? '',
      filterList: filterMap,
    },
    schema ?? [],
    {
      disabled: !batchMetadata || !batchMetadata.batchId || !validDatasetLocation || !filtersValid,
      cacheTime: 0,
      staleTime: Infinity,
    },
  );

  const { data: businessRules, isFetching: businessRulesIsFetching } = useBatchBusinessRules(
    {
      datasetName: currentDataset,
      planType: batchMetadata?.costType as PlanTypeId,
      region: batchMetadata?.region as RegionId,
    },
    { disabled: !batchMetadata },
  );

  useEffect(() => {
    setIsHasUnsavedEdits(inlineEdits.length > 0);
  }, [inlineEdits.length, setIsHasUnsavedEdits]);

  /** generate table data using data fetched from BE  */
  useEffect(() => {
    if (!rawData || !businessRules || !columnDefs) return;

    const validHeaders = schema?.map((item) => item.dimensionName) ?? [];

    const validationRowTransformer = getValidationRowTransformer(
      currentDataset,
      businessRules,
      columnDefs,
      schema,
      featureFlags?.usingValidationFramework,
      batchMetadata?.costType,
    );

    if (!validationRowTransformer) return;

    const newRowData = standardizeInitialData(
      featureFlags?.usingValidationFramework,
      rawData.dataset,
      validationRowTransformer,
      indexedId,
    );

    setInlineEdits([]);

    setGridData({ rowData: newRowData, validationRowTransformer, validHeaders });

    /** initialize savedUnsolvedErrorRowIds */
    const errorRowIds = getErrorRowIds(newRowData);
    setSavedUnsolvedErrorRowIds(errorRowIds);

    /** navigate to the first error row */
    setTimeout(() => {
      navigateToNextErrorRowIds(gridRef, errorRowIds);
    }, 300);
  }, [
    rawData,
    businessRules,
    columnDefs,
    schema,
    featureFlags?.usingValidationFramework,
    batchMetadata?.costType,
    currentDataset,
  ]);

  const currentDatasetLabel = datasetOptions.find((item) => item.value === currentDataset)?.label;

  const clearGrid = () => {
    setGridData(null);
    setInlineEdits([]);
  };

  // Clear Grid when filters Change
  useEffect(() => {
    if (filtersValid && filterMap) clearGrid();
  }, [filterMap, filtersValid]);

  // Clear Grid when dataset changes
  const handleChangeSelectWithConfirmation = (e: SelectChangeEvent) => {
    const newDataset = e.detail.selectedOption.value as SupportedDatasetType;

    if (newDataset === currentDataset) return;

    if (isHasUnsavedEdits) {
      setConfirmModalInfo({
        header: t('discard_changes'),
        message: t('change_tables_unsaved_changes_warning'),
        onProceed: () => {
          setCurrentDataset(newDataset);
          clearGrid();
        },
      });
    } else {
      setCurrentDataset(newDataset);
      clearGrid();
    }
  };

  const handleSaveDataset = useCallback(() => {
    if (!rawData || !batchMetadata || !gridData || inlineEditsDisabled) {
      setIsUpdatingDataset(false);
      return;
    }

    const batchEdits = inlineEdits.map((item) => {
      switch (item.editType) {
        case IInlineEditType.ADD:
          return { previous: [], current: item.current };
        case IInlineEditType.MODIFY:
          return { previous: item.previous, current: item.current };
        case IInlineEditType.DELETE:
          return { previous: item.previous, current: [] };
      }
    });

    const { partitionS3VersionMap, partitionLocator } = rawData.metadata;

    const lastUpdatedOn = getLastUpdatedOnOfDataset(batchMetadata, currentDataset);

    const inlineEditsUploadArguments: RequestVariables<UploadBatchEditsRequest> = {
      batchId: batchMetadata.batchId ?? '',
      batchEdits,
      headers: gridData.validHeaders,
      datasetType: currentDataset,
      lastUpdatedOn,
      partitionS3VersionMap: partitionS3VersionMap ?? null,
      partitionLocator: partitionLocator ?? null,
      operation: OperationType.UPSERT,
    };

    updateNormalDatasetMutation(inlineEditsUploadArguments);
  }, [
    batchMetadata,
    currentDataset,
    inlineEdits,
    inlineEditsDisabled,
    rawData,
    gridData,
    updateNormalDatasetMutation,
  ]);

  useEffect(() => {
    if (shouldTriggerDatasetUpdate) {
      setShouldTriggerDatasetUpdate(false);
      handleSaveDataset();
    }
  }, [handleSaveDataset, shouldTriggerDatasetUpdate]);

  const { UnsavedChangesModal } = useUnsavedChangesModal({
    isHasUnsavedEdits,
    onDiscard: () => setInlineEdits([]),
    onSave: handleSaveDataset,
  });

  const actionButtonGroups = useMemo<DataGridActionButtonGroups>(() => {
    const isTopsDownEditableOutputDataset =
      TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES.has(currentDataset);

    const addRow = () => {
      const gridApi = gridRef.current?.api;
      if (
        !gridApi ||
        !gridData?.validationRowTransformer ||
        !gridData?.validHeaders ||
        gridData.validHeaders.length === 0
      ) {
        return;
      }

      const newIndexedId = getNewIndexedId();

      const newRow: IRowValidationRowWithId = {
        id: {
          value: newIndexedId,
          original: newIndexedId,
          lastValidationSuccess: true,
          ...(featureFlags?.usingValidationFramework && {
            lastValidationNoWarning: true,
          }),
          validationErrors: [],
        },
      };

      for (const header of gridData.validHeaders) {
        const newValue: IRowValidationCellData = {
          value: null,
          original: null,
          lastValidationSuccess: true,
          ...(featureFlags?.usingValidationFramework && {
            lastValidationNoWarning: true,
          }),
          validationErrors: [],
        };

        newRow[header] = newValue;
      }

      const formattedRow = gridData.validationRowTransformer(newRow);

      const inlineEditsUpdater = agRowInlineEditsAdd(formattedRow, gridData.validHeaders);
      setInlineEdits(inlineEditsUpdater);

      gridApi.applyTransactionAsync({ add: [formattedRow], addIndex: 0 }, (resultNodes) => {
        gridApi.paginationGoToFirstPage();
        gridApi.ensureIndexVisible(0);
        // workaround to make flashCells work for newly created cells
        setTimeout(() => {
          gridApi.flashCells({ rowNodes: resultNodes?.add });
        }, 300);
      });
    };

    const handleCloneRows = () => {
      const gridApi = gridRef.current?.api;

      if (
        !gridApi ||
        !gridData?.validationRowTransformer ||
        !gridData?.validHeaders ||
        gridData.validHeaders.length === 0
      ) {
        return;
      }

      const selectedRows = gridApi.getSelectedRows();

      if (selectedRows.length === 0) return;

      const rowsToAdd = selectedRows.map((row: IRowValidationRowWithId, idx: number) => {
        const record: IRowValidationRowWithId = {
          id: {
            value: `${idx + indexedId.current}`,
            original: `${idx + indexedId.current}`,
            lastValidationSuccess: true,
            ...(featureFlags?.usingValidationFramework && {
              lastValidationNoWarning: true,
            }),
            validationErrors: [],
          },
        };

        for (const [key, cellData] of Object.entries(row)) {
          if (key !== 'id') {
            const newValue: IRowValidationCellData = {
              value: cellData.value,
              original: null,
              lastValidationSuccess: true,
              ...(featureFlags?.usingValidationFramework && {
                lastValidationNoWarning: true,
              }),
              validationErrors: [],
            };

            record[key] = newValue;
          }
        }
        return gridData.validationRowTransformer(record);
      });

      indexedId.current += selectedRows.length;

      for (const row of rowsToAdd) {
        const inlineEditsUpdater = agRowInlineEditsAdd(row, gridData.validHeaders);
        setInlineEdits(inlineEditsUpdater);
      }

      gridApi.applyTransactionAsync({ add: rowsToAdd, addIndex: 0 }, (resultNodes) => {
        gridApi.paginationGoToFirstPage();
        gridApi.ensureIndexVisible(0);
        gridApi.deselectAll();
        // workaround to make flashCells work for newly created cells
        setTimeout(() => {
          gridApi.flashCells({ rowNodes: resultNodes?.add });
        }, 300);
      });
    };

    const handleDeleteRows = () => {
      const gridApi = gridRef.current?.api;
      if (!gridData || !gridApi) {
        return;
      }

      const selectedRows = gridApi.getSelectedRows();

      for (const row of selectedRows) {
        const inlineEditsUpdater = agRowInlineEditsDelete(row, gridData.validHeaders);
        setInlineEdits(inlineEditsUpdater);
      }

      gridApi.applyTransaction({ remove: selectedRows });
    };

    const handleClickExpand = () => {
      setIsFullScreen(true);
    };

    const reloadData = () => {
      if (OTRAutomatedImportDatasetsWithTrailingSelector.has(currentDataset)) {
        triggerOtrObAutomatedDatasetImport({
          batchId: batchMetadata?.batchId ?? '',
          parameters: buildComputeParams({ datasetName: currentDataset }),
        });
      } else {
        refetchPlanDataset();
        clearGrid();
      }
    };

    const reloadDataWithConfirmation = () => {
      if (isHasUnsavedEdits) {
        setConfirmModalInfo({
          header: t('discard_changes'),
          message: t('refresh_data_unsaved_changes_warning'),
          onProceed: reloadData,
        });
      } else {
        reloadData();
      }
    };

    const showImportModalWithConfirmation = () => {
      if (isHasUnsavedEdits) {
        setConfirmModalInfo({
          header: t('discard_changes'),
          message: t('import_unsaved_changes_warning'),
          onProceed: () => {
            setIsImportModalVisible(true);
          },
        });
      } else {
        setIsImportModalVisible(true);
      }
    };

    const handleWebbImport = () => {
      if (!batchMetadata?.batchId) return;
      triggerImportBaseline({ batchId: batchMetadata.batchId });
    };

    const handleLunaExport = () => {
      if (!batchMetadata?.batchId) return;
      triggerLunaExport({ batchId: batchMetadata.batchId });
    };

    const handleSaveButtonClick = () => {
      setIsUpdatingDataset(true);
      /** Give time for ag grid to commit any cells currently being edited
       *  and inlineEdits to reflect the new value **/
      setTimeout(() => setShouldTriggerDatasetUpdate(true), 500);
    };

    const handleClickExport = () => {
      /** temporarily disable rowSelection to make exporting works properly due to an ag grid defect */
      const rowSelection = gridRef.current?.api.getGridOption('rowSelection');
      gridRef.current?.api.setGridOption('rowSelection', undefined);

      const planName = (batchMetadata?.batchName ?? '').replaceAll('.', '_');
      const fileName = `${currentDataset}_${planName}_${currentDateTime()}`;

      /** export file through the ag grid api */
      gridRef.current?.api.exportDataAsExcel({
        fileName,
        exportedRows:
          batchMetadata?.costType === PlanTypeId.FIXED_COST_CONSOLIDATION
            ? 'filteredAndSorted'
            : 'all',
        processHeaderCallback: getExportProcessHeaderCallback(mappingSchema),
        processCellCallback: (params) => params.value,
      });

      /** after exporting, restore rowSelection configuration */
      gridRef.current?.api.setGridOption('rowSelection', rowSelection);
    };

    return [
      [
        {
          'data-testid': 'save-button',
          icon: FaRegSave,
          text: t('save'),
          onClick: handleSaveButtonClick,
          disabled: isUpdatingDataset || currentGridPivotMode,
          hide: inlineEditsDisabled,
        },
        {
          icon: RiImportFill,
          text: t('webb_import'),
          onClick: handleWebbImport,
          disabled: isUpdatingDataset,
          hide: currentDataset !== SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE,
        },
        {
          'data-testid': 'import-button',
          icon: BiImport,
          text: t('import'),
          onClick: showImportModalWithConfirmation,
          disabled: isUpdatingDataset || currentGridPivotMode,
          hide: isTopsDownEditableOutputDataset,
        },
        {
          icon: RiExportFill,
          text: t('luna_export'),
          onClick: handleLunaExport,
          disabled: isUpdatingDataset,
          hide: currentDataset !== SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT,
        },
        {
          'data-testid': 'export-button',
          icon: BiExport,
          text: t('export'),
          onClick: handleClickExport,
          disabled: isUpdatingDataset || currentGridPivotMode,
          hide: false,
        },
        {
          'data-testid': 'refresh-button',
          icon: BiRefresh,
          text: t('refresh'),
          onClick: reloadDataWithConfirmation,
          disabled: isUpdatingDataset,
          hide: false,
        },
      ],
      [
        {
          'data-testid': 'add-row-button',
          icon: MdAdd,
          text: t('add_row'),
          onClick: addRow,
          disabled: isUpdatingDataset || currentGridPivotMode,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
        {
          'data-testid': 'clone-row-button',
          icon: FaRegClone,
          text: t('clone_rows'),
          onClick: handleCloneRows,
          disabled: isUpdatingDataset || currentGridPivotMode,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
        {
          'data-testid': 'delete-button',
          icon: BsFillArchiveFill,
          text: t('delete'),
          onClick: handleDeleteRows,
          disabled: isUpdatingDataset || currentGridPivotMode,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
      ],
      [
        {
          'data-testid': 'expand-button',
          icon: AiOutlineFullscreen,
          text: t('expand'),
          onClick: handleClickExpand,
          disabled: isUpdatingDataset,
          hide: false,
        },
      ],
    ];
  }, [
    currentDataset,
    t,
    isUpdatingDataset,
    currentGridPivotMode,
    inlineEditsDisabled,
    gridData,
    getNewIndexedId,
    featureFlags?.usingValidationFramework,
    batchMetadata?.batchId,
    refetchPlanDataset,
    triggerOtrObAutomatedDatasetImport,
    isHasUnsavedEdits,
    batchMetadata?.batchName,
    batchMetadata?.costType,
    triggerImportBaseline,
    triggerLunaExport,
    mappingSchema,
  ]);

  const containerDescription = useMemo(() => {
    const datasetErrorInfo = getDatasetErrorInfo(batchMetadata, currentDataset);

    if (datasetErrorInfo.message) {
      return (
        <Button
          variant="inline-link"
          disabled={!datasetErrorInfo.downloadLink}
          onClick={() =>
            downloadFile(datasetErrorInfo.downloadLink!, featureFlags?.feS3UrlGeneration ?? false)
          }
        >
          {datasetErrorInfo.message}
        </Button>
      );
    }

    const currentDatasetValidationDetails =
      batchMetadata?.datasetStatus?.detailedDatasetStatus?.find(
        (o) => o.datasetName === currentDataset,
      )?.validationDetails;

    const currentDatasetValidationStatus = currentDatasetValidationDetails?.status;

    if (
      currentDatasetValidationStatus &&
      currentDatasetValidationStatus !== ValidationStatusEnumEnum.Success
    ) {
      return (
        <Button
          variant="inline-link"
          disabled={!currentDatasetValidationDetails.messageLocation}
          onClick={() => {
            downloadFile(
              currentDatasetValidationDetails.messageLocation!,
              featureFlags?.feS3UrlGeneration ?? false,
            );
          }}
        >
          {currentDatasetValidationDetails.message}
        </Button>
      );
    }

    return <span>{description}</span>;
  }, [batchMetadata, currentDataset, description, featureFlags?.feS3UrlGeneration]);

  const rowData = useMemo(() => {
    if (validDatasetLocation) return gridData?.rowData;
    if (planDatasetIsFetching || businessRulesIsFetching) return undefined;
    return [];
  }, [validDatasetLocation, gridData?.rowData, planDatasetIsFetching, businessRulesIsFetching]);

  const { defaultColDef } = useInputAgGridDefaultColDef({
    validationRowTransformer: gridData?.validationRowTransformer,
    gridRef,
    validHeaders: gridData?.validHeaders,
    setInlineEdits,
    inlineEditsDisabled,
    savedUnsolvedErrorRowIds,
    setSavedUnsolvedErrorRowIds,
  });

  const { resizeColumns } = useAgGridResizeColumns(gridRef, containerRef);

  const { gridHeight } = useAgGridCalculatedHeight(containerRef, 65, !!FilterComponent);

  const {
    restorePivotModeConfig,
    onColumnRowGroupChanged,
    onColumnPivotChanged,
    onColumnValueChanged,
    onColumnPivotModeChanged,
  } = useAgGridPivotModeConfig({ setCurrentGridPivotMode });

  const getRowId = useCallback(
    (params: any) => params?.data?.id?.value ?? getNewIndexedId(),
    [getNewIndexedId],
  );

  const cellSelection = useMemo<CellSelectionOptions>(() => ({ handle: { mode: 'range' } }), []);

  const rowSelection = useMemo<RowSelectionOptions>(() => ({ mode: 'multiRow' }), []);

  const onToolPanelVisibleChanged = useCallback(
    (e: ToolPanelVisibleChangedEvent) => {
      setCurrentGridToolPanel(e.visible ? e.key : undefined);
    },
    [setCurrentGridToolPanel],
  );

  const uploadDatasetMutationMethod = isLargeDataset
    ? updateLargeDatasetMutation
    : updateNormalDatasetMutation;

  return (
    <>
      <DatasetGridContainer
        currentDataset={currentDataset}
        onDatasetSelected={handleChangeSelectWithConfirmation}
        datasetOptions={datasetOptions}
        headerText={headerText}
        description={containerDescription}
        actionButtonGroups={actionButtonGroups}
      >
        {FilterComponent}

        <div ref={containerRef}>
          <AgGridBaseWrapper
            gridRef={gridRef}
            rowBuffer={10}
            tooltipShowDelay={1000}
            suppressColumnVirtualisation={true}
            onModelUpdated={resizeColumns}
            onPaginationChanged={resizeColumns}
            onCellEditingStopped={resizeColumns}
            onGridReady={restorePivotModeConfig}
            onColumnRowGroupChanged={onColumnRowGroupChanged}
            onColumnPivotChanged={onColumnPivotChanged}
            onColumnValueChanged={onColumnValueChanged}
            onColumnPivotModeChanged={onColumnPivotModeChanged}
            onToolPanelVisibleChanged={onToolPanelVisibleChanged}
            gridHeight={gridHeight}
            isFullScreen={isFullScreen}
            setFullScreen={setIsFullScreen}
            pagination={true}
            paginationPageSize={AG_INPUT_PAGINATION_PAGE_SIZE}
            defaultColDef={defaultColDef}
            cellSelection={cellSelection}
            rowSelection={rowSelection}
            animateRows={true}
            rowData={rowData}
            columnDefs={columnDefs ?? []}
            getRowId={getRowId}
            pivotMode={currentGridPivotMode}
            gridToolPanel={currentGridToolPanel}
            aggFuncs={aggFuncs}
          />
        </div>
      </DatasetGridContainer>

      <UploadDatasetModal
        visible={isImportModalVisible}
        currentDataset={currentDataset}
        currentDatasetLabel={currentDatasetLabel}
        isUpdatingDataset={isUpdatingDataset}
        datasetMetadata={rawData?.metadata}
        validHeaders={gridData?.validHeaders}
        mappingSchema={mappingSchema}
        gridRef={gridRef}
        onClose={() => setIsImportModalVisible(false)}
        uploadDatasetMutationMethod={uploadDatasetMutationMethod}
      />

      <UserConfirmModal
        confirmModalInfo={confirmModalInfo}
        setConfirmModalInfo={setConfirmModalInfo}
      />

      {UnsavedChangesModal}
    </>
  );
};

export default PlanInputDataGrid;
