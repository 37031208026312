import {
  GetDatasetDataRequest,
  GetDatasetMetadataResponse,
} from '@amzn/fox-den-cost-planning-lambda';
import { useMemo, useState } from 'react';
import { ColDef, IServerSideDatasource } from 'ag-grid-community';
import { useParams } from 'react-router-dom';
import { PlanTypeId, RegionId, SupportedDatasetType } from 'src/utils/planning/planetModel';
import { getColumnDefs, useDatasetSchema } from 'src/utils/planning/useDatasetSchema';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useSsrmDataset } from 'src/pages/commons/data-grids/useSsrmDataset';
import { useBatchBusinessRules } from 'src/api/query/useBatchDimensionValues';
import {
  IMappingSchemaColumnItem,
  ITableDataSchemaColumnItem,
} from 'src/utils/planning/batchMetadataModel';
import {
  getValidationRowTransformer,
  IValidationRowTransformer,
  standardizeInitialData,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridRowValidationHelper';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

/**
 * mostly we use react-query hooks to manage api calls in this package
 * however, to work seamlessly with ag grid server side row model,
 * we need to use a general async function for fetching datasets
 */

class InputSsrmDatasetAttributes {
  schema: ITableDataSchemaColumnItem[] | undefined = undefined;
  mappingSchema: IMappingSchemaColumnItem[] | undefined = undefined;
  columnDefs: ColDef[] | undefined = [];
  validationRowTransformer: IValidationRowTransformer | undefined = undefined;
  datasetMetadata: GetDatasetMetadataResponse | undefined = undefined;
  datasetRowCount: number | undefined = undefined;
  datasetRequest: GetDatasetDataRequest | undefined = undefined;
}

export const useSsrmInputDataset = (currentDataset: SupportedDatasetType) => {
  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: featureFlags } = useFeatureFlags();

  const { data: businessRules } = useBatchBusinessRules(
    {
      datasetName: currentDataset,
      planType: batchMetadata?.costType as PlanTypeId,
      region: batchMetadata?.region as RegionId,
    },
    { disabled: !batchMetadata },
  );

  const { schema, mappingSchema } = useDatasetSchema({ batchMetadata, currentDataset });

  const { getDataset, isFetchingDataset } = useSsrmDataset(currentDataset);

  const [attributes, setAttributes] = useState(() => new InputSsrmDatasetAttributes());

  const serverSideDatasource = useMemo<IServerSideDatasource | undefined>(() => {
    if (!batchMetadata || !businessRules) return undefined;

    return {
      getRows: async (params) => {
        const { request, success, fail } = params;

        const { datasetData, datasetMetadata, datasetRequest } = await getDataset(request);

        const columnDefs = getColumnDefs({
          batchMetadata,
          datasetMetadata,
          schema,
          mappingSchema,
          featureFlags,
          isSsrmDataGrid: true,
        });

        if (!datasetData || !datasetMetadata || !columnDefs) {
          fail();
          return;
        }

        const validationRowTransformer = getValidationRowTransformer(
          currentDataset,
          businessRules,
          columnDefs,
          schema,
          featureFlags?.usingValidationFramework,
          batchMetadata?.costType,
        );

        const rowData = standardizeInitialData(
          featureFlags?.usingValidationFramework,
          datasetData.rowData || [],
          validationRowTransformer,
        );

        const datasetRowCount = datasetData.totalRowCount ?? undefined;

        setAttributes({
          schema,
          mappingSchema,
          columnDefs,
          validationRowTransformer,
          datasetMetadata,
          datasetRowCount,
          datasetRequest,
        });

        success({ rowData, rowCount: datasetRowCount });
      },
    };
  }, [
    batchMetadata,
    businessRules,
    currentDataset,
    featureFlags,
    getDataset,
    mappingSchema,
    schema,
  ]);

  return { serverSideDatasource, isFetchingDataset, ...attributes };
};
